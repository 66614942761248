import { Component, OnInit } from '@angular/core';
import {Go} from "./go";

@Component({
  selector: 'app-go',
  templateUrl: './go.component.html',
  styleUrls: ['./go.component.scss']
})
export class GoComponent implements OnInit {

  public go: Go;

  constructor() {
    this.go = Go.create();
  }

  ngOnInit(): void {
  }

}
