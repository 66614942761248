import { Component, OnInit } from '@angular/core';
import {Game} from "./game";

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {
  public nav = true;

  public game: Game = {
    name: null,
    players: []
  };

  constructor() {
    this.game.name = 'P1 v P2'
  }

  ngOnInit(): void {
  }

}
