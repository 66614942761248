<!-- Poster -->
<div class="page coming-soon-page">
    <!-- Header -->

    <!-- Logo -->
    <div class="logo">
        <!-- Logo: Title -->
        <p class="logo-title">
            <strong>Snakes &amp; Ladders</strong>
        </p>
        <!-- Logo: Image -->
        <img class="logo-img" [src]="logo.src"/>
    </div>

    <!-- Content -->
    <div class="content">
        <!-- [Article] About -->
        <article class="coming-soon-article">
            <p>Snakes and Ladders is coming soon</p>
        </article>
    </div>

    <!-- Footer -->
    <div class="footer">
        <p>&copy; Web Apps Ltd 2020</p>
    </div>
</div>
