import { Component, OnInit } from '@angular/core';
import {Ladder} from "./ladder";
import {LadderFactory} from "./ladder.factory";

@Component({
  selector: 'app-ladder',
  templateUrl: './ladder.component.html',
  styleUrls: ['./ladder.component.scss']
})

export class LadderComponent implements OnInit {

  public ladder: Ladder;

  constructor() {
    this.ladder = LadderFactory.create();
  }

  ngOnInit(): void {
  }

}
