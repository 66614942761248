import {GoInterface} from "./go.interface";

export class Go implements GoInterface {
  svg: string;

  constructor(svg: string) {
    this.svg = svg;
  }

  static create(): GoInterface {
    return new Go(`assets/img/game/go/go.svg`);
  }
}
