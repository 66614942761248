import {SnakeInterface} from "./snake.interface";

export class Snake implements SnakeInterface {
  color: string;
  svg: string;

  constructor(color: string, svg: string) {
    this.color = color;
    this.svg = svg;
  }
}
