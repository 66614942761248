import { Component, OnInit } from '@angular/core';
import {Logo} from "./logo/logo";

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  public logo: Logo = {
    src: 'assets/img/logo/logo.svg'
  };

  constructor() {

  }

  ngOnInit(): void {
  }

}
