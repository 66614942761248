import {SnakeInterface} from "./snake.interface";
import {Snake} from "./snake";

const COLORS = ['green', 'pink'];

export class SnakeFactory {

  static create(): SnakeInterface {
    const color = COLORS[Math.floor(Math.random() * COLORS.length)];
    return new Snake(color, `assets/img/game/snake/snake.${color}.svg`);
  }
}
