import {LadderInterface} from "./ladder.interface";
import {Ladder} from "./ladder";

const COLORS = ['green', 'brown'];

export class LadderFactory {

  static create(): LadderInterface {
    const color = COLORS[Math.floor(Math.random() * COLORS.length)];
    return new Ladder(color, `assets/img/game/ladder/ladder.${color}.svg`);
  }
}
