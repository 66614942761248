import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameComponent } from './game.component';
import { SnakeComponent } from './snake/snake.component';
import { BoardComponent } from "./board/board.component";
import { LadderComponent } from './ladder/ladder.component';
import { GoComponent } from "./go/go.component";

@NgModule({
  declarations: [GameComponent, SnakeComponent, BoardComponent, LadderComponent, GoComponent],
  imports: [
    CommonModule
  ],
  exports: [GameComponent]
})
export class GameModule { }
