import { Component, OnInit } from '@angular/core';
import {Snake} from "./snake";
import {SnakeFactory} from "./snake.factory";

@Component({
  selector: 'app-snake',
  templateUrl: './snake.component.html',
  styleUrls: ['./snake.component.scss']
})
export class SnakeComponent implements OnInit {

  public snake: Snake;

  constructor() {
    this.snake = SnakeFactory.create();
  }

  ngOnInit(): void {
  }

}
