import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  public title = 'snakes-and-ladders';
  private routerEvents: any;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.routerEvents.unsubscribe();
  }

  isEnabled(key: string): boolean {
    let isEnabled = false;
    // Nav
    if (key === 'nav') {
      if (this.router.url === '/play') {
        isEnabled = true;
      }
    }
    return isEnabled
  }
}
