import {LadderInterface} from "./ladder.interface";
import {LadderFactory} from "./ladder.factory";

export class Ladder implements LadderInterface {
  color: string;
  svg: string;

  constructor(color: string, svg: string) {
    this.color = color;
    this.svg = svg;
  }
}
